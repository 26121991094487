import React, { useRef, useState } from "react";
import { Link } from "gatsby"
import gsap, { TimelineLite } from "gsap";

import carouselStyles from "./carousel.module.css";
import arrow from "../images/arrow.svg"
import Image from "../components/image"

const Carousel = (props) => {
    const [ carouselState, setCarouselState ] = useState({
        index: 0
    });

    let headline = useRef(null);
    let subHeadline = useRef(null);
    let link = useRef(null);
    let imageOverlay = useRef(null);
    let controls = useRef(null);

    const textTimeline = new TimelineLite();
    const fadeText = () => {
        textTimeline.set(controls, {
            pointerEvents: 'none'
        }).to([link.current, subHeadline, headline], 1, {
            opacity: 0,
            y: 30,
            ease: "power3.out",
            stagger: {
                amount: 0.3
            }
        }).to([headline, subHeadline, link.current], 1, {
            opacity: 1,
            y: 0,
            ease: "power3.out",
            stagger: {
                amount: 0.3
            }
        }).set(controls, {
            pointerEvents: 'auto'
        });
    }

    const imageTimeline = new TimelineLite();
    const fadeImage = () => {
        imageTimeline.to(imageOverlay, 0.8, {
            delay: 0.2,
            left: '0',
            ease: "power3.inOut"
        }).to(imageOverlay, 0.8, {
            delay: 1,
            left: '-100%'
        }).set(imageOverlay, {
            left: '100%'
        })
    }

    const startAnimation = () => {
        fadeText();
        fadeImage();
    }

    const changeSlide = (toRight) => {
        if(toRight) {
            startAnimation();
            setTimeout(() => {
                if(carouselState.index === (props.slides.length-1)) {
                    setCarouselState({ index: 0 });
                    return
                }
                setCarouselState({ index: carouselState.index+1 })
            }, 1000);
            clearTimeout(autoplay);
        } else {
            startAnimation();
            setTimeout(() => {
                if(carouselState.index === 0) {
                    setCarouselState({ index: (props.slides.length-1) });
                    return
                }
                setCarouselState({ index: carouselState.index-1 })
            }, 1000);
            clearTimeout(autoplay);
        }
    }

    const autoplay = setTimeout(() => {
        changeSlide(true);
    }, 100000); // TODO: change to 10 000

    let showLogo = props.slides[carouselState.index].image === "IMG_8415.jpg" ? { display: "flex" } : { display: "none" };
    return (
            <div className={carouselStyles.carousel}>
                <div className={carouselStyles.textContainer}>
                    <h1 ref={el => (headline = el)}>{props.slides[carouselState.index].headline}</h1>
                    <p ref={el => (subHeadline = el)}>{props.slides[carouselState.index].subHeadline}</p>
                    <Link ref={link} to={props.slides[carouselState.index].link}>{props.slides[carouselState.index].linkTitle}</Link>
                </div>
                <div className={carouselStyles.imageContainer}>
                    <Image alt="ASDF" filename={props.slides[carouselState.index].image} />
                    <div ref={el => (imageOverlay = el)} className={carouselStyles.imageOverlay}></div>
                </div>
                <div ref={el => (controls = el)} className={carouselStyles.controls}>
                    <div className={carouselStyles.leftControl} onClick={() => { changeSlide(false) }}>
                        <img src={arrow} alt="Left Arrow"/>
                    </div>
                    <div className={carouselStyles.rightControl} onClick={() => { changeSlide(true) }}>
                        <img src={arrow} alt="Right Arrow"/>
                    </div>
                </div>
            </div>
    )
}

export default Carousel;