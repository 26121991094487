import React, { useEffect } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ProductView from "../components/productView";
import Carousel from "../components/carousel";
import Slidy from "../components/slidy";

const IndexPage = () => {
    // useful for initial Animation (only executes once when dependencies ([]) are empty)
    useEffect(() => {

    }, []);

    const carouselSlides = [
        {
            headline: "Willkommen bei Honigprodukte Maierhofer",
            subHeadline: "Entdecken Sie unsere Vielfalt an Honigsorten, Fruchtaufstrichen sowie Naturprodukten und erfahren Sie mehr über unsere Labestation und Cateringservice.",
            image: "IMG_8415.jpg",
            linkTitle : "Mehr erfahren",
            link: "/ueber-uns/"
        },
        {
            headline: "Honig aus der Region Bad Radkersburg",
            subHeadline: "Genießen Sie unsere Klassiker. Waldblütenhonig, Cremehonig und Waldhonig. Von Hand gemacht aus der Südostteiermark.",
            image: "Klassiker_Rechteck.png",
            linkTitle : "zum Honig",
            link: "/honigsorten/"
        },
        {
            headline: "Honig mit Frucht. oder Nuss",
            subHeadline: "Entdecken Sie unsere selbstgemachten Genusssorten. Unser Honig verfeinert mit Frucht oder Nuss erlaubt neue, köstliche Geschmäcker.",
            image: "Frucht-und-Nuss_Rechteck.png",
            linkTitle : "Sorten entdecken",
            link: "/honigsorten/"
        },
        {
            headline: "Natur und Honig schenken.",
            subHeadline: "Ob wunderbar duftende Kerzen aus Bienenwachs oder Naturkosmetik aus der Region, entdecken Sie unsere ausgewählten Honig- und Naturprodukte.",
            image: "Schenken_Rechteck.png",
            linkTitle : "stöbern",
            link: "/produkte/"
        }
    ]

    const experimentierSorten = [
        {
            name: "Haselnusshonig",
            info: "Diese besondere Honigkreation bietet Ihnen Abwechslung am Frühstückstisch.\nBei Kindern sehr beliebt!",
            sizes: "250g",
            image: "Haselnusshonig.png",
            alt: "Haselnusshonig"
        },
        {
            name: "Kastanienhonig",
            info: "Diese ganz besondere Honigsorte bietet ein kräftiges Aroma mit leicht bitterer Note.\nKastanienhonig ist auch gesundheitsfördernd und wirkt positiv auf den Kreislauf und das Immunsystem.",
            sizes: "125g bis 1kg",
            image: "Kastanienhonig.png",
            alt: "Kastanienhonig"
        },
        {
            name: "Erdbeer Honig",
            info: "Zutaten:\nÖsterreichischer Honig,\n5% vakuumgetrocknete Erdbeeren (entspricht 60% Frischfrucht),\nZitronensaft",
            sizes: "220g",
            image: "Erdbeerhonig.png",
            alt: "Erdbeer Honig",
        }
    ];

    const sliderImages= [
        {
            image: "IMG_8377.jpg",
            alt: "Haus"
        },
        {
            image: "IMG_2125.jpg",
            alt: "Verkaufsraum Außen"
        },
        {
            image: "IMG_8415.jpg",
            alt: "Verkaufsraum Innen"
        }
    ]

    // TODO: also change Image alt="" to something useful
    return (
        <Layout>
            <SEO title="Startseite" />
            <Carousel slides={carouselSlides} />
            <ProductView headline="Experimentieren Sie." subHeadline="und entdecken Sie den vielfältigen Geschmack unserer Honigsorten." products={experimentierSorten} />
            <Slidy sliderImages={sliderImages} />
        </Layout>
    )
}

export default IndexPage
